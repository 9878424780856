<template>
	<div class="chat-list">
		<div class="chat-list__header">
			<div class="chat-list__header--img">
				<img
					src="~@/assets/images/svg/magnifying-glass.svg"
					width="26"
					height="25"
					alt="Иконка поиска"
				/>
			</div>

			<input
				type="text"
				v-model="chat.roomQuery"
				class="chat-list__header--search"
				placeholder="Поиск по чатам"
			/>
		</div>

		<div class="chat-list__chats">
			<div
				v-for="room in chat.rooms"
				:key="room.id"
				class="chats-item"
				@click.stop="chat.joinRoom(room.id)"
			>
				<div class="userpic">
					<tk-image
						:src="room.recipient.avatar || '/images/default-avatar.svg'"
						height="60"
						width="60"
						:alt="room.recipient.name"
					/>
				</div>
				<div class="text">
					<div class="text__wrapper">
<!--						<div class="online-status">-->
<!--							Онлайн-->
<!--							<span>{{ getDateTime(room.recipient?.time_last_online) }}</span>-->
<!--						</div>-->
						<div class="username">{{ room.recipient?.name }}</div>
					</div>

					<div class="message-preview">
            <div class="missed" v-if="room.hasUnreadMessages()" />
            <div v-html="renderMsgAsText(room.lastMessage?.text)" />
            {{ room.hasUnreadMessages() ? '(есть непрочитанные собщения)' : '' }}
          </div>
					<!-- <div class="booking-status inprogress">
						<div>Запрос на бронирование</div>
						<div class="block" @click.stop="showChatBlockingDialogModal">
							<div class="block-img">
								<img
									src="~@/assets/images/svg/disabled.svg"
									alt="Иконка Заблокировать"
								/>
							</div>
							<div class="block-text">Заблокировать</div>
						</div>
					</div> -->
					<!-- <div class="booking-status rejected">
						<div>Запрос отклонен</div>
						<div class="block" @click.stop="showChatBlockingDialogModal">
							<div class="block-img">
								<img
									src="../assets/images/svg/disabled.svg"
									alt="Иконка Заблокировать"
								/>
							</div>
							<div class="block-text">Заблокировать</div>
						</div>
					</div> -->
					<!-- <div class="booking-status confirmed">
						<div>Бронирование подтверждено</div>
						<div class="block" @click.stop="showChatBlockingDialogModal">
							<div class="block-img">
								<img
									src="~@/assets/images/svg/disabled.svg"
									alt="Иконка Заблокировать"
								/>
							</div>
							<div class="block-text">Заблокировать</div>
						</div>
					</div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { renderMsgAsText } from "@/utils/bbcodeHelpers";

export default {
  inject: ['chat'],
	unmounted() {
    this.chat.roomQuery = ''
  },
  methods: {
    renderMsgAsText,
  }
}
</script>

<style lang="sass">
.chat-list
  width: 330px
  min-width: 330px
  max-height: 810px
  background: #FFFFFF
  border: 1px solid #E2E2E2
  border-radius: 20px 0px 0px 20px
  &__header
    padding: 31px 10px 30px 20px
    display: flex
    border-bottom: 1px solid #E2E2E2
    &--img
      margin-right: 20px
    &--search
      border: none
      font-size: 14px
      line-height: 14px
      color: #3B3F47
      caret-color: #1AC386

      background-color: transparent

      &:hover,
      &:focus,
      &:active
        outline: 0

      &:disabled
        cursor: not-allowed
  &__chats
    max-height: 726px
    overflow-y: auto
  .chats-item
    display: flex
    position: relative
    padding-top: 15px
    padding-left: 15px
    transition: 0.1s linear

    &:hover
      cursor: pointer
    &.notification
      &:before
        content: ''
        position: absolute
        right: 9px
        width: 8px
        height: 8px
        border-radius: 50%
        background: #1AC386
    &.active
      background: #F5F5F5
    .userpic
      margin-right: 15px
      img
        border-radius: 50%
        object-fit: cover
    .text
      width: 100%
      border-bottom: 0.5px solid #E2E2E2
      padding-bottom: 5px
      padding-right: 15px
      .call
        display: none
      .online-status
        font-size: 12px
        line-height: 12px
        color: #CBCBCB
        margin-bottom: 5px
        display: flex
        gap: 5px
        border-radius: 50%
        align-items: center
        span.online-dot
          position: relative
          width: 5px
          height: 5px
          background-color: #1AC386
          border-radius: 50%
      .username
        font-size: 14px
        font-weight: 600
        line-height: 14px
        color: #3B3F47
        margin-bottom: 5px
      .message-preview
        word-break: break-all
        position: relative
        font-size: 12px
        line-height: 14px
        color: #CBCBCB
        margin-bottom: 10px
        padding-right: 10px
        .missed
          position: absolute
          width: 8px
          height: 8px
          border-radius: 50%
          background-color: gray
          margin-left: 10px
          right: 0
          top: 0
        & > div
          overflow: hidden
          text-overflow: ellipsis
          display: -moz-box
          -moz-box-orient: vertical
          display: -webkit-box
          -webkit-line-clamp: 2
          -webkit-box-orient: vertical
          line-clamp: 2
      .booking-status
        font-weight: 600
        font-size: 10px
        line-height: 10px
        text-align: right
        display: flex
        align-items: center
        justify-content: space-between
        .block
          display: none
          align-items: center
          &:hover
            .block-text
              text-decoration: underline
              cursor: pointer
          .block-img
            margin-right: 5px
          .block-text
            color: #E93030
            font-size: 12px
            line-height: 12px

        &.confirmed
          color: #1AC386
        &.inprogress,
        &.question
          color: #FF922F
        &.rejected
          color: #E93030
        &.changed
          color: rgba(64, 134, 244, 0.8)
        &.complete
          color: rgba(203, 203, 203, 1)
        &.request
          color: #FFDA2D
        &.canceled
          color: #574343
    .call
      position: absolute
      right: 25px
      top: 33px
    &:hover
      background: #F5F5F5

@media screen and (max-width: 1000px)
  .chat-list
    border: 0
    &__chats
      display: flex
      flex-direction: column
      gap: 15px
    &__header
      margin-bottom: 15px
      padding: 0
      border-bottom: 0

      &--img
        display: none

      &--search
        display: block
        width: 100%
        padding: 10px 20px
        border: 1px solid #E2E2E2
        border-radius: 12px
        background: #FFFFFF
        box-sizing: border-box

        &:hover,
        &:focus,
        &:active
          border: 1px solid $green

    .chats-item
      position: relative
      padding: 0
      background-color: #FFFFFF
      border: 0.5px solid #E2E2E2
      border-radius: 10px

      .userpic
        position: absolute
        top: 15px
        left: 15px

      .text
        width: 100%
        padding: 0
        border-bottom: 0

        .online-status
          display: block
        &__wrapper
          padding: 30px 0
          padding-bottom: 15px
          padding-left: 90px

        .username
          margin: 0
          padding: 0

        .message-preview
          margin: 0
          margin-top: 15px
          padding: 15px
          font-style: normal
          font-weight: 400
          font-size: 14px
          line-height: 16px
          color: #3B3F47
          border-top: 0.5px solid #E2E2E2
          .missed
            right: 10px
            top: 50%

@media screen and (max-width: 800px)
  .chat-list
    width: 100%
    min-width: 100%
</style>
